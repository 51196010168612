





















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart, BarGroupedByValuesChartResult } from '@/models'
import { VueApexCharts as ApexChart, ApexCharts, es } from '@/plugins/apexcharts'
import numeral from '@/plugins/numeral'
import openLink from '@/utils/route/openLink'
import _mapValues from 'lodash/mapValues'
import _values from 'lodash/values'

@Component({
  components: {
    ApexChart
  }
})
export default class BarGroupedByValuesChart extends Vue {
  @Prop({ type: Object, required: true }) chart !: Chart
  @Prop({ type: Object, required: true }) result !: BarGroupedByValuesChartResult
  @Prop({ type: Object, default: () => ({}) }) queryOptions !: Record<string, any>
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Boolean, default: false }) loading !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>

  get chartOptions () : ApexCharts.ApexOptions {
    return {
      chart: {
        id: this.chart._id,
        type: 'line',
        locales: [es],
        defaultLocale: 'es',
        events: {
          dataPointSelection: (ev, chartContext, el) => {
            if (!this.result.links || this.preview) return
            const dataset = this.result.datasets[Object.keys(this.result.datasets)[el.seriesIndex]]
            const data = dataset[el.dataPointIndex]
            openLink(this.result.links, { ...this.viewParams, ...data })
          }
        }
      },
      // @ts-ignore
      stroke: {
        width: Object.keys(this.result.datasets).map((key, i) => this.result.charts[i] === '$dot' ? 0 : 5),
        curve: Object.keys(this.result.datasets).map((key, i) => this.result.charts[i] === '$curvedLine' ? 'smooth' : 'straight')
      },
      markers: {
        size: 6
      },
      states: {
        active: {
          allowMultipleDataPointsSelection: false
        }
      },
      xaxis: {
        categories: this.xaxis,
        labels: {
          style: {
            colors: this.$vuetify.theme.dark ? '#EEEEEE' : []
          }
        }
      },
      // @ts-ignore
      yaxis: {
        labels: {
          formatter: (v, o) => {
            switch (this.result.valueType) {
              case 'number':
                return numeral(v).format('0,0.[00]') || v
              case 'money':
              case 'currency':
                return numeral(v).format('$0,0.[00]') || v
              case 'percentage':
                return numeral(v).format('0,0.[00]%') || v
              default:
                return v
            }
          },
          style: {
            colors: this.$vuetify.theme.dark ? '#EEEEEE' : []
          }
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        formatter: (v, o) => {
          const dataset = this.rawSeries[o.seriesIndex]
          const data = dataset.data[o.dataPointIndex]
          return data && data._dataLabel || ''
        },
        offsetX: 0
      },
      tooltip: {
        theme: this.$vuetify.theme.dark ? 'dark' : 'light'
      },
      colors: this.result.specificColors && this.result.specificColors.split('&&') || undefined,
      legend: {
        labels: {
          useSeriesColors: true
        }
      }
    }
  }

  get rawSeries () {
    return Object.keys(this.result.datasets).map((key, i) => ({
      name: this.result.titles && this.result.titles[i] && this.result.titles[i].title || `Set de datos ${i}`,
      data: this.xaxis.map(x => {
        const matches = this.result.datasets[key].find(d => d.x === x)
        return matches || { x, y: 0, _dataLabel: '' }
      }),
      type: this.transformChartType(this.result.charts[i])
    }))
  }

  get series () {
    return Object.keys(this.result.datasets).map((key, i) => ({
      name: this.result.titles && this.result.titles[i] && this.result.titles[i].title || `Set de datos ${i}`,
      data: this.xaxis.map(x => {
        const matches = this.result.datasets[key].find(d => d.x === x)
        return matches && matches.y || 0
      }),
      type: this.transformChartType(this.result.charts[i])
    }))
  }

  get flatSeries () {
    return this.series.map(s => s?.data).filter(s => s).flat()
  }

  get xaxis () : string[] {
    const datasetKeys = Object.keys(this.result.datasets)
    const merged = []
    for (const dataset in this.result.datasets) {
      if (!this.result.datasets.hasOwnProperty(dataset)) continue
      merged.push(...this.result.datasets[dataset])
    }
    return Array.from(new Set<string>(merged.map((d : any) => d.x)))
  }

  transformChartType (inputType : string) {
    return ({
      '$bar': 'column',
      '$line': 'line',
      '$dot': 'line',
      '$curvedLine': 'line'
    } as Record<string, string>)[inputType] || 'line'
  }
}
